import $store from '@/store'
import $router from '@/router'
import $api from '@/api'
import LocalStorageHelper from '@/helper/LocalStorageHelper'
import {Modal} from 'ant-design-vue'

const beforeEnter = async (to: any, from: any) => {
    const wxStudentTrainSignUpIncomplete = LocalStorageHelper.load('wx_student_train_sign_up_incomplete')
    if (wxStudentTrainSignUpIncomplete?.schoolCourseId) {
        Modal.confirm({
            content: '您有未完成的培训报名，是否要继续',
            okText: '继续',
            cancelText: '不再提示',
            onOk: () => {
                $router.push('/wx_home/school_course/find/' + wxStudentTrainSignUpIncomplete?.schoolCourseId)
            },
            onCancel: () => {
                LocalStorageHelper.save('wx_student_train_sign_up_incomplete', null)
            }
        })
    }

    if ($store.getters['student/logged']) {
        return true
    }

    await $api.get('/student/student/logged').then(async ({data: {status, msg, result}}: any) => {
        if (1 == status) {
            $store.commit('student/logged', result)
        } else {
            // 是否是微信环境
            if (window.navigator.userAgent.match(/MicroMessenger/i)) {
                if (to?.query?.openid) {
                    const openId = to?.query?.openid;
                    LocalStorageHelper.save('wx_user', {openId})
                    // 微信自动登录
                    await $api.get('/student/student/wx_login', {
                        params: {openId}
                    }).then(async ({data: {status}}: any) => {
                        if (1 === status) {
                            await $api.get('/student/student/logged').then(({data: {status, msg, result}}: any) => {
                                if (1 == status) {
                                    $store.commit('student/logged', result)
                                }
                            })
                        } else {
                            $router.push('/wx_home/student/login')
                        }
                    })
                } else {
                    // 是否已有保存的openId
                    const wxUser = LocalStorageHelper.load('wx_user')
                    if (wxUser?.openId) {
                        const openId = wxUser?.openId;
                        // 微信自动登录
                        await $api.get('/student/student/wx_login', {
                            params: {openId}
                        }).then(async ({data: {status}}: any) => {
                            if (1 === status) {
                                await $api.get('/student/student/logged').then(({data: {status, msg, result}}: any) => {
                                    if (1 == status) {
                                        $store.commit('student/logged', result)
                                    }
                                })
                            } else {
                                $router.push('/wx_home/student/login')
                            }
                        })
                    } else {
                        // 获取微信授权地址 发起授权
                        await $api.get('/api/weixin/oauth2_url', {
                            params: {state: 'wx_student'}
                        }).then(({data: {status, result}}: any) => {
                            if (1 === status && result.data) {
                                window.location.href = result.data
                            } else {
                                $router.push('/wx_home/student/login')
                            }
                        })
                    }
                }
            } else {
                $router.push('/wx_home/student/login')
            }
        }
    })

    return !!$store.getters['student/logged'];
}

export default [
    {
        path: '/wx_student',
        component: () => import('@/page/wx_home/WxHomeLayoutMain.vue'),
        meta: {
            title: '首页',
        },
        children: [
            {path: '/wx_student/:pathMatch(.*)', component: () => import('@/page/wx_student/NotFond.vue')},
            {path: '/wx_student', component: () => import('@/page/wx_student/index/WxStudentIndexIndex.vue')},
            {
                path: '/wx_student/exam_plan/lists',
                component: () => import('@/page/wx_student/exam_plan/WxStudentExamPlanLists.vue')
            },
            {
                path: '/wx_student/exam_sign_up/lists',
                component: () => import('@/page/wx_student/exam_sign_up/WxStudentExamSignUpLists.vue')
            },
            {
                path: '/wx_student/invoice/lists',
                component: () => import('@/page/wx_student/invoice/WxStudentInvoiceLists.vue')
            },
            {
                path: '/wx_student/notice/lists',
                component: () => import('@/page/wx_student/notice/WxStudentNoticeLists.vue')
            },
            {
                path: '/wx_student/student_authorization/lists',
                component: () => import('@/page/wx_student/student_authorization/WxStudentStudentAuthorizationLists.vue')
            },
            {
                path: '/wx_student/student_certificate/lists',
                component: () => import('@/page/wx_student/student_certificate/WxStudentStudentCertificateLists.vue')
            },
            {
                path: '/wx_student/student_course_progress/lists',
                component: () => import('@/page/wx_student/student_course_progress/WxStudentStudentCourseProgressLists.vue')
            },
            {
                path: '/wx_student/student_course_progress_log/index/:studentCourseProgressId',
                component: () => import('@/page/wx_student/student_course_progress_log/WxStudentStudentCourseProgressLogIndex.vue')
            },
            {
                path: '/wx_student/student_evaluate_school/lists',
                component: () => import('@/page/wx_student/student_evaluate_school/WxStudentStudentEvaluateSchoolLists.vue')
            },
            {
                path: '/wx_student/student_exam/lists',
                component: () => import('@/page/wx_student/student_exam/WxStudentStudentExamLists.vue')
            },
            {
                path: '/wx_student/student_exercise/save',
                component: () => import('@/page/wx_student/student_exercise/WxStudentStudentExerciseSave.vue')
            },
            {
                path: '/wx_student/student_exercise_paper/lists',
                component: () => import('@/page/wx_student/student_exercise_paper/WxStudentStudentExercisePaperLists.vue')
            },
            {
                path: '/wx_student/student_exercise_paper_history_question/lists',
                component: () => import('@/page/wx_student/student_exercise_paper_history_question/WxStudentStudentExercisePaperHistoryQuestionLists.vue')
            },
            {
                path: '/wx_student/student_exercise_paper_question/lists',
                component: () => import('@/page/wx_student/student_exercise_paper_question/WxStudentStudentExercisePaperQuestionLists.vue')
            },
            {
                path: '/wx_student/student_idea/lists',
                component: () => import('@/page/wx_student/student_idea/WxStudentStudentIdeaLists.vue')
            },
            {
                path: '/wx_student/train_sign_up/lists',
                component: () => import('@/page/wx_student/train_sign_up/WxStudentTrainSignUpLists.vue')
            },
            {
                path: '/wx_student/student/profile',
                component: () => import('@/page/wx_student/student/WxStudentStudentEdit.vue')
            },
            {
                path: '/wx_student/student/profile/:type',
                component: () => import('@/page/wx_student/student/WxStudentStudentEdit.vue')
            },
        ],
        beforeEnter,
    },
]