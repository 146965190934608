import state from '@/store/modules/supervise/state'
import getters from '@/store/modules/supervise/getters'
import mutations from '@/store/modules/supervise/mutations'
import actions from '@/store/modules/supervise/actions'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
