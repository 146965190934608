import $store from '@/store'
import $api from '@/api'

const beforeEnter = async (to: any, from: any) => {
    $api.get('/exam/exam_user/logged').then(({data: {status, msg, result}}: any) => {
        if (1 == status) {
            $store.commit('examUser/logged', result)
        }
    })
}

export default [
    {
        path: '/exam',
        component: () => import('@/page/exam/ExamLayoutMain.vue'),
        meta: {
            title: '首页',
        },
        children: [
            {path: '/exam/:pathMatch(.*)', component: () => import('@/page/exam/NotFond.vue')},
            {path: '/exam', component: () => import('@/page/exam/index/ExamIndexIndex.vue')},
            {
                path: '/exam/exam_plan/lists_resit',
                component: () => import('@/page/exam/exam_plan/ExamExamPlanListsResit.vue')
            },
            {path: '/exam/exam_user/index', component: () => import('@/page/exam/exam_user/ExamExamUserIndex.vue')},
            { path: '/exam/exam_user/edit', component: () => import('@/page/exam/exam_user/ExamExamUserEdit.vue') },
            { path: '/exam/go', component: () => import('@/page/exam/exam_monitor/examMonitor.vue') },
        ],
        beforeEnter,
    },
    {
        path: '/exam/student',
        component: () => import('@/page/exam/student/ExamStudentIndex.vue')
    },
    {
        path: '/exam/mobile/exam_plan_practice_user/:token',
        component: () => import('@/page/exam/mobile/ExamMobileExamPlanPracticeUser.vue')
    },
    {
        path: '/exam/mobile/exam_plan_student/:token',
        component: () => import('@/page/exam/mobile/ExamMobileExamPlanStudent.vue')
    },
]